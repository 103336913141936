import { MenuFoldOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import fullLogo from "assets/images/logo.png";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { allowDown } from "svg/svg";
import { servicesData } from "utils/serviceData";

import css from "./Header.module.scss";
const HomePageHeader = () => {
  const [navExpanded, setNavExpanded] = useState(false);
  const [isWhite, setIsWhite] = useState(true);
  const [ismobile, setIsmobile] = useState<boolean>(false);
  const [isServiceOpen, setiIsServiceOpen] = useState<boolean>(false);

  // const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

const handleScroll = () => {
  const scrollPosition = window.scrollY;
  const targetDiv = document.getElementsByClassName("fixed-top")[0];
  
  // 获取当前路径
  const pathName = location.pathname;

  // 如果路径包含 /caseStudyDetail，则移除样式
  if (pathName.includes('/caseStudyDetail')) {
    targetDiv?.classList?.remove(css.homeBgTransparent);
    setIsWhite(false); // 如果需要设置白色背景
    return; // 退出函数，不进行后续操作
  }else{
  if (scrollPosition <= 30 && window.innerWidth >= 992) {
    targetDiv?.classList?.add(css.homeBgTransparent);
    setNavLinkBg();
  } else {
    targetDiv?.classList?.remove(css.homeBgTransparent);
    setIsWhite(false);
  }
  }
  const navElements = document.querySelectorAll("nav a");
  // @ts-expect-error TS(2339): Property 'blur' does not exist on type 'Element'.
  navElements.forEach((element) => element.blur());
};

  const setNavLinkBg = () => {
    const { pathname } = window.location;
    if (["/", "/caseStudy"].includes(pathname)) {
      setIsWhite(true);
    } else {
      setIsWhite(false);
    }
  };
  const justifyWindowWdith = () => {
    if (window.innerWidth >= 992) {
      setIsmobile(false);
    } else {
      setIsmobile(true);
    }
  };
  const resizeUpdate = () => {
    justifyWindowWdith();
    handleScroll();
    setNavExpanded(false)
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", resizeUpdate);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", resizeUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    resizeUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    setiIsServiceOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navExpanded]);

  const goToService = (item: any) => {
    navigate(`/services/${item.id}`);
    window.location.reload()
  };
  const popoverContent = (
    <div className={cn(css.popoverContainer)}>
      {servicesData.map((item: any, index) => {
        if ((index + 1) % 2 === 0) {
          let nowIndex = index;
          let beforeIndex = index - 1;
          return (
            <div key={index} className={cn(css.row)}>
              <div
                key={servicesData[beforeIndex].name}
                className={cn(css.cell)}
                onClick={() => {
                  goToService(servicesData[beforeIndex]);
                }}
              >
                <div className={cn(css.leftIcon)}>
                  {servicesData[beforeIndex].icon}
                </div>
                <div>{servicesData[beforeIndex].name}</div>
              </div>
              <div
                key={servicesData[nowIndex].name}
                className={cn(css.cell)}
                onClick={() => {
                  goToService(servicesData[nowIndex]);
                }}
              >
                <div className={cn(css.leftIcon)}>
                  {servicesData[nowIndex].icon}
                </div>
                <div>{servicesData[nowIndex].name}</div>
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      })}
    </div>
  );

  return (
    <>
      <nav
        id="header-nav"
        className={cn(
          css.navWarp,
          css.homeBgTransparent,
          "navbar navbar-expand-lg navbar-dark fixed-top"
        )}
        aria-label="Third navbar example"
      >
        <div className={cn(css.navContainer, "container")}>
          <Link className="navbar-brand" to="/">
            <img src={fullLogo} className={css.fullLogo} alt="fullLogo" />
          </Link>
          <div className={css.navRight}>
            {ismobile && (
              <li
                className={cn(css.classDisplay, css.mobileContactBtn)}
                onClick={() => setNavExpanded(false)}
              >
                <a className={cn(css.contactBtn)} href="/contactUs">
                  <div className="book-call-content-wrap">
                    <div>Contact Us</div>
                  </div>
                </a>
              </li>
            )}
            <button
              className={cn(css.menuBtn, "custom-toggler navbar-toggler")}
              type="button"
              aria-controls="navbarsExample03"
              aria-expanded={navExpanded}
              aria-label="Toggle navigation"
              onClick={() => setNavExpanded(!navExpanded)}
            >
              <MenuFoldOutlined className={css.menuIcon} />
            </button>
          </div>
          <div
            className={`collapse navbar-collapse${navExpanded ? " show" : ""
              }`}
            id="navbarsExample03"
          >
            <ul className="navbar-nav ms-auto d-flex align-items-start align-items-lg-center justify-content-end w-100">
              {[
                // {
                //   name: "aboutUs",
                //   ref: `${process.env.REACT_APP_DOMAIN_URL}aboutUs`,
                //   text: "About Us",
                // } ,
                {
                  name: "home",
                  ref: `/`,
                  text: "Home",
                },
                {
                  name: "services",
                  text: "Services",
                },
                {
                  name: "aboutUs",
                  ref: `/aboutUs`,
                  text: "About Us",
                },
                // {
                //   name: "blog",
                //   ref: `/blog`,
                //   text: "Blog",
                // },
                {
                  name: "caseStudy",
                  ref: `/caseStudy`,
                  text: "Case Study",
                },
              ].map(({ name, ref, text }) => {
                if (name === "services") {
                  //
                  return (
                    <li key={name} className={cn(css.navItem)}>
                      {!ismobile && (
                        <Popover
                          overlayClassName={cn(css.popoverDrop, "popoverDrop")}
                          content={popoverContent}
                          placement="bottom"
                        >
                          <Link
                            to="/servicesMain"
                            className={cn(
                              "nav-link",
                              css.navigation,
                              isWhite && css.white
                            )}
                            onClick={() => setNavExpanded(false)}
                          >
                            {text} {allowDown}
                          </Link>
                        </Popover>
                      )}
                      {ismobile && (
                        <>
                          <div className={css.serviceNav}>
                            <Link
                              to="/servicesMain"
                              className={cn(
                                "nav-link",
                                css.navigation,
                                isWhite && css.white
                              )}
                              onClick={() => setNavExpanded(false)}
                            >
                              {text}
                            </Link>
                            <span onClick={() => { setiIsServiceOpen(!isServiceOpen) }}>{allowDown}</span>
                          </div>
                          {isServiceOpen && popoverContent}
                        </>
                      )}
                    </li>
                  );
                } else {
                  return (
                    <li key={name} className={cn(css.navItem)}>
                      <Link
                        to={ref}
                        className={cn(
                          "nav-link",
                          css.navigation,
                          isWhite && css.white
                        )}
                        onClick={() => setNavExpanded(false)}
                      >
                        {text}
                      </Link>
                    </li>
                  );
                }
              })}
              {!ismobile && (
                <li className={cn(css.classDisplay)}>
                  <a className={cn(css.contactBtn)} href="/contactUs">
                    <div className="book-call-content-wrap">
                      <div>Contact Us</div>
                    </div>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default HomePageHeader;
