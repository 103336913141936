import React, { useEffect } from "react";
import { Outlet } from "react-router";
import { useLocation } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/Header";

const Layout = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <div className="home-page-sallis">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default React.memo(Layout);
