export const HomePageTransEN = {
  home: "home",
  resources: "resources",
  contact: "contact",
  "cost-calculator": "Cost Calculator",
  "customer-portal": "Customer Portal",
  "launch-firm-online":
    "Seamless company setup and corporate services, all online.",
  "try-now": "Try Now",
  "start-business": "Start a business hassle-free.",
  "company-formation-procedure":
    "makes the company formation procedures in the UAE easy - that’s our thing.",
  "you-can-form": "You can form your ",
  "uae-company": "UAE company",
  "visa-bank-account-online":
    ", issue visas, and get the best support to open a bank account all online.",
  "web-portal-helps": "Our web portal allows you to ",
  "setup-your-business": "incorporate your company",
  "fulfill-needs": " and fulfill its needs conveniently.",
  with: "With",
  "it-is-a": ", it is a",
  "painless-fast": " painless, fast",
  and: " and",
  efficient: " efficient",
  process: " process",
  "how-it-works": "How it works",
  faqs: "FAQs",
  "how-it-works-desc":
    "We walk you through the essentials and take care of the rest for you. Spend a few minutes to mention your needs, then we'll initiate the legal procedures.",
  "legal-entity-form": "Partnership with Jurisdictions",
  "legal-entity-form-desc1":
    "Whether it’s in Dubai for a media agency, or in Abu Dhabi for an industrial company. ",
  "legal-entity-form-desc2": ` handles all the work.`,
  "share-founder-stock": "Shareholder Structure",
  "share-founder-stock-desc1": `From the simplest setup for a single person company to complex corporate shareholder structures, `,
  "share-founder-stock-desc2": ` enables you to setup your company based on any specification.`,
  "account-with-stripe": "Online Payment",
  "account-with-stripe-desc":
    "Don't bother with cheques or bank transfers - pay online and get your invoice instantly. Track any of your corporate services in catalog in the customer portal.",
  "helpful-community": "Continuous Support",
  "helpful-community-desc":
    "Get in touch with our dedicated customer care and business development teams for any inquiry that you might need for your professional needs. We'll book a session for you right away.",
  "services-we-provide": "Services",
  "company-setup": "Company Setup",
  "mainland-business-licenses":
    "Mainland, Freezone, and Offshore business formation",
  memorandum: "Availability to form your business for any activity type",
  "activity-desc-certificate": "Get your government-issued documents online",
  checkout: "checkout",
  "visa-permit": "Visa Services",
  "investor-visa": "Investor Visa",
  "residence-visa": "Residence Permit",
  "golden-visa": "Golden Visa",
  "visa-status-change": "Status change & appointment management",
  "emirated-id-services": "Emirates ID",
  "financial-solution": "Corporate Services & File Management",
  "online-application-convenience": "Manage everything online",
  use: "Use ",
  "coop-bank": ` repository for your applications & companies`,
  "medical-insurance": "Corporate bank account opening assistance",
  faq: "FAQ’s",
  "faq-one": " What are the advantages of setting up a company in the UAE?",
  "faq-one-desc-para1": `Tax benefits: There is no corporate or personal income tax in the UAE, making it an attractive location for businesses and entrepreneurs.`,
  "faq-one-desc-para2": `Business-friendly policies: The UAE has a relatively simple and straightforward regulatory environment, with few restrictions on foreign ownership and investment.`,
  "faq-one-desc-para3": `Access to a large market: The UAE has a large and growing consumer market, with a high standard of living and a significant influx of expatriates.`,
  "faq-two": " Do I need to be in the UAE to setup a company?",
  "faq-two-desc": `No, it is not necessary to be physically present in the UAE to set up a company. Many entrepreneurs and investors use the services of local sponsors or business setup consultants who can handle the company formation process on their behalf. However, it is advisable to visit the UAE and conduct due diligence before finalizing any business arrangement. It may also be necessary to sign certain documents in person or through a power of attorney.`,
  "faq-three":
    " What is the difference between a mainland and a freezone company?",
  "faq-three-desc-para1": `The main differences between the two entities are: `,
  "faq-three-desc-para2": `Licensing: Generally, freezone companies are allowed to conduct business outside the UAE or within the freezone itself, whereas mainland companies are allowed to conduct business in and out of the UAE with no limitation.`,
  "faq-three-desc-para3": `Jurisdiction: Mainland companies are subject to UAE federal law, while freezone companies are subject to the laws and regulations of the specific freezone authority.`,
  "faq-three-desc-para4": `Business activities: Mainland companies have more flexibility in terms of the types of businesses they can engage in, while freezone companies are typically restricted to specific activities within the freezone.`,
  "faq-four": " Do I need to have an Emirates ID to form a company? ",
  "faq-four-desc-para1": `Even though it is a convenience, it is not absolutely required to have an Emirates ID to start your company. The Emirates ID is a mandatory identification card for all UAE residents and citizens, and is used for various purposes, including opening bank accounts, obtaining visas, and conducting business activities.`,
  "faq-four-desc-para2": `For foreign nationals who are forming a company in partnership with a local sponsor, the sponsor will typically be responsible for obtaining the Emirates ID on their behalf. In other cases, it may be necessary for the individual to apply for an Emirates ID in person, either at an Emirates ID center or through a representative.`,
  does: `Does `,
  "faq-five": " take care of all of these and more? ",
  "faq-five-desc": "Seamlessly, yes!",
  company: "Company",
  "visa-issuance": "Visa Issuance",
  "residence-permit": "Residence Permit",
  "financial-services": "Financial Services",
  "financial-solutions": "Financial Solutions",
  "privacy-policy": "Privacy Policy",
  "terms-and-conditions": "Terms & Conditions",
  "contact-info": "Contact Info",
  "sallis-address":
    "Sallis Digital Document Clearing Services LLC, H Dubai, 28th Floor, Sheikh Zayed Rd., Dubai/UAE",
  "sallis-email": "hello@sallis.io",
  "sallis-phone": "+971 50 272 7106",
  "copyright-2023 ": "Copyright 2023 ",
  sallis: "Sallis",
  "all-rights-reserved": " All rights reserved.",
};

export default HomePageTransEN;
