export const enTrans = {
  dashboard: "Dashboard",
  applications: "Applications",
  documents: "Documents",
  services: "Services",
  "your-company": "Your Company",
  "need-help?": "need help?",
  logout: "Logout",
  "formation-info": "Formation Info",
  "setup-type": "Setup Type",
  "purpose&activities": "Purpose & Activities",
  "visa-allocation": "Visa Allocation",
  amenities: "Amenities",
  "let’s-start-with-the-basics": "let’s start with the basics",
  "save-&-continue-later": "SAVE & CONTINUE LATER",
  back: "BACK",
  next: "NEXT",
  "a-bit-more-about-your-needs": "a bit more about your needs",
  "about-your-new-venture": "about your new venture",
  "something-major-is-forming-up": "something major is forming up",
  "final-step-we-are-almost-done": "final step, we are almost done",
  "almost-there!": "100% - almost there!",
  "pick-up-where-you-left-off": "pick up where you left off",
  "no-account-text": "Don’t have an account?",
  login: "Login",
  "sign-up": "Sign up",
  "forgot-password": "Forgot Password",
  "welcome!": "welcome!",
  "with-sallis-handle-everything-online":
    "with sallis, you can handle everything online",
  "issue-visas": "issue visas",
  "trading-license": "get a trading license",
  "company-in-dubai": "setup a company in Dubai",
  "track-your-applications-online": "track your applications online",
  "assign-power-of-attorney": "assign power of attorney",
  "handle-everything-online": "handle everything online",
  "total-number-of-shareholders": "What is the total number of shareholders?",
  "partner-information": "Information of Partner",
  "get-started-the": "get started the",
  sallis: "Sallis",
  way: "way",
  "with-sallis-start-and-manage-company-online":
    "with Sallis, you can start your company and manage it online",
  "create-your-account": "Create your account",
  "provide-company-details": "Provide company details",
  "submit-required-documents": "Submit required documents",
  "pay-online-in-the-portal": "Pay online in the portal",
  "reset-password": "Reset password",
  "save-new-password": "Save new password",
  "confirm-password": "Confirm password",
  "new-password": "New password",
  "get-you-in-first": "let’s get you in first",
  "first-name": "First Name",
  "last-name": "Last Name",
  email: "Email",
  "mobile-number": "Mobile Number",
  password: "Password",
  "create-account": "Create account",
  "already-have-account": "Already have an account ?",
  "confirm-sign-up": "Confirm Sign Up",
};

export default enTrans;
