import {
  blueLingxing,
  greenLingxing,
  serviceIcon1,
  serviceIcon2,
  serviceIcon3,
  serviceIcon4,
  yellowLingxing,
  zfx,
  ziseLingxing,
} from 'svg/svg'
export const servicesData = [
  {
    id: 1,
    icon: blueLingxing,
    name: 'Machine Learning and AI Data Analytics',
    desc:
      'Leveraging machine learning and AI for data analytics is crucial for staying competitive. Our solutions offer deep insights and smarter decision-making to enhance operational efficiency and customer satisfaction.',
      subName:"What is a Machine Learning and AI Data?",
      subDesc:"Machine learning uses data to teach computers to recognise patterns and make decisions without explicit programming. AI data encompasses the vast amount of information, both structured and unstructured, essential for training and refining these machine learning models. These elements drive the development and enhancement of artificial intelligence systems.",
    benefits: [
      {
        icon: serviceIcon1,
        title: 'Enhanced Automation',
        description:
          'Machine Learning automates complex and repetitive tasks, significantly boosting work efficiency and accuracy. For instance, in manufacturing, Machine Learning algorithms can predict equipment failures, enabling preventative maintenance.',
      },
      {
        icon: serviceIcon2,
        title: 'Improved Data Analytics',
        description:
          'Machine Learning techniques process and analyse large datasets, identifying patterns and trends. This is invaluable in industries that require interpreting vast amounts of data, such as retail, finance, and healthcare.',
      },
      {
        icon: serviceIcon3,
        title: 'Personalised Experiences',
        description:
          'Machine Learning analyses user behaviour and preferences to provide highly personalised content and recommendations. This greatly enhances the user experience in areas like online shopping, streaming services, and more.',
      },
      {
        icon: serviceIcon4,
        title: 'Optimised User Interaction',
        description:
          'With intelligent user interfaces like chatbots and virtual assistants, Machine Learning improves user interaction by understanding natural language and providing quick, accurate responses. This streamlines communication and service delivery.',
      },
    ],
    worksSteps: [
      {
        step: 'STEP ONE',
        title: 'Requirement Gathering and Analysis',
        subTitle:
          'Customised requirement analysis conducted through expert consultations to align with your strategic objectives.',
        outputs: 'Comprehensive Brief, List of research topics.',
      },
      {
        step: 'STEP TWO',
        title: 'Data Collection and Cleaning',
        subTitle:
          'Sources of essential data identified and harvested, followed by rigorous cleansing to ensure integrity.',
        outputs:
          'Revised Value Proposition, List of Main Assumptions to Prove, Initial User Stories.',
      },
      {
        step: 'STEP THREE',
        title: 'Data Preparation',
        subTitle:
          'Advanced feature engineering techniques are applied to refine data, enhancing model accuracy and performance.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP FOUR',
        title: 'Model Selection and Development',
        subTitle:
          'Selection and bespoke development of the most suitable machine learning or AI models based on specific data traits and business goals.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP FIVE',
        title: 'Model Evaluation and optimisation',
        subTitle:
          'Models are critically evaluated and fine-tuned using state-of-the-art optimisation techniques to meet predefined success criteria.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP SIX',
        title: 'Deployment and Integration',
        subTitle:
          'Final models are deployed and integrated into your existing technological framework, ensuring full functionality and optimal performance.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
    ],
  },
  {
    id: 2,
    icon: greenLingxing,
    name: 'Web/Mobile Development',
    desc:
      'Web/Mobile Development involves creating applications and websites for web browsers and mobile devices, including designing, coding, and testing software for optimal performance and user experience.',
    subName:"What is a Web/Mobile Development?",
    subDesc:"Web/Mobile Development process includes designing, coding, and testing software to ensure top performance and an excellent user experience. Our services encompass the full development cycle, from custom application and website creation to performance optimisation and user experience improvements. With expertise in programming, frameworks, and tools, we deliver functional and user-friendly digital products tailored to meet your needs.",
    benefits: [
      {
        icon: serviceIcon1,
        title: 'Accessibility',
        description:
          'Web/mobile apps provide instant access to information and services anytime, anywhere. Users can easily engage with content on their preferred devices, enhancing convenience and reach.',
      },
      {
        icon: serviceIcon2,
        title: 'Interactivity',
        description:
          'These platforms enable rich user interactions, improving engagement. Features like real-time updates, personalised content, and interactive elements create immersive experiences.',
      },
      {
        icon: serviceIcon3,
        title: 'Scalability',
        description:
          'Cyberate Technologies ensures that your applications can grow seamlessly with your business. Our solutions are designed to handle increased loads and user demands efficiently, allowing you to scale up without compromising on performance or user experience.',
      },
      {
        icon: serviceIcon4,
        title: 'Cost-Effectiveness',
        description:
          'Developing for web/mobile platforms is often more cost-effective than traditional software development. Cross-platform tools and frameworks further reduce development time and costs.',
      },
    ],
    worksSteps: [
      {
        step: 'STEP ONE',
        title: 'Project Planning and Design',
        subTitle:
          'Based on the clients needs, formulate project plans and design schemes. Determine the technical requirements, functional requirements, and schedule, and create corresponding project documents.',
        outputs: 'Comprehensive Brief, List of research topics.',
      },
      {
        step: 'STEP TWO',
        title: 'Interface Design and User Experience (UI/UX)',
        subTitle:
          'Design the user interface of websites or mobile applications to ensure user-friendly and engaging experiences. This includes creating interface prototypes, visual designs, and interaction designs.',
        outputs:
          'Revised Value Proposition, List of Main Assumptions to Prove, Initial User Stories.',
      },
      {
        step: 'STEP THREE',
        title: 'Development and Coding',
        subTitle:
          'Carry out the development work of websites or mobile applications according to the project plan and design scheme. This may involve tasks such as front-end development, back-end development, database design, and integration.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP FOUR',
        title: 'Testing and optimisation',
        subTitle:
          'Test the developed website or mobile application to ensure its functionality, performance, and security. Optimise and fix bugs based on test results.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP FIVE',
        title: 'Deployment and Release',
        subTitle:
          'Deploy the website or mobile application to the production environment and release it. Ensure a smooth deployment process so that the website or application can be accessed and used normally.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope,Tech Spike, Business Model.',
      }
    ],
  },
  {
    id: 3,
    icon: yellowLingxing,
    name: 'Computer Vision Development',
    desc:
      'Explore the forefront of visual solutions with our advanced computer vision services, designed to boost efficiency and enrich customer experiences through innovative technologies.',
    subName:"What is a Computer Vision Development?",
    subDesc:"Computer Vision Development involves creating algorithms and techniques that enable machines to interpret and understand visual information. It allows computers to recognise, analyse, and respond to images and videos, facilitating tasks like object detection, image classification, and facial recognition. This technology is crucial for automating various processes and enhancing decision-making capabilities in numerous industries.",
      benefits: [
      {
        icon: serviceIcon1,
        title: 'Automation',
        description:
          'Computer vision automates image and video processing, significantly reducing manual labour. This technology can quickly and accurately analyse vast amounts of visual data, enabling efficient automation of tasks like quality control, security monitoring, and object tracking.',
      },
      {
        icon: serviceIcon2,
        title: 'Accuracy and Precision',
        description:
          'Computer vision systems can achieve high levels of accuracy and precision in tasks such as object recognition, facial identification, and pattern detection. This capability is crucial in applications where minute details are essential, like medical imaging or precision manufacturing.',
      },
      {
        icon: serviceIcon3,
        title: 'Adaptability',
        description:
          'Computer vision algorithms can be tailored to suit different environments and scenarios. With advances in machine learning, these systems can continuously learn and improve their performance, making them highly adaptable to changing conditions and requirements.',
      },
      {
        icon: serviceIcon4,
        title: 'Cost-Efficiency',
        description:
          'By automating visual inspection tasks, computer vision systems can significantly reduce labour costs and increase operational efficiency. This technology also helps minimise errors and inconsistencies associated with manual processes, further enhancing cost-effectiveness.',
      },
    ],
    worksSteps: [
      {
        step: 'STEP ONE',
        title: 'Requirements Collection and Analysis',
        subTitle:
          'Engagements start with thorough client communication to capture all project-specific needs and goals.',
        outputs: 'Comprehensive Brief, List of research topics.',
      },
      {
        step: 'STEP TWO',
        title: 'Technical Consultation and Planning',
        subTitle:
          'Plans and roadmaps are developed, pinpointing technology and resources needed for tailored solutions.',
        outputs:
          'Revised Value Proposition, List of Main Assumptions to Prove, Initial User Stories.',
      },
      {
        step: 'STEP THREE',
        title: 'Data Preparation and Collection',
        subTitle:
          'Assistance is provided in preparing and collecting essential data sets, ensuring quality and completeness.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP FOUR',
        title: 'Model Design and Development',
        subTitle:
          'Tailored computer vision models are designed and developed using the latest in image processing and machine learning techniques.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP FIVE',
        title: 'Model Training and optimisation',
        subTitle:
          'Models undergo training and optimisation, refining performance based on real-time feedback.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP SIX',
        title: 'Deployment and Integration',
        subTitle:
          'Models are deployed and seamlessly integrated into client systems, enhancing existing functionalities.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      }
      
    ],
  },
  {
    id: 4,
    icon: zfx,
    name: 'Digital Twin Development',
    desc:
      'Create virtual replicas of physical assets and systems for enhanced operational insights and efficiency across various industries.',
      subName:"What is Digital Twin Development?",
      subDesc:"Digital twin development involves creating a virtual replica of a physical asset, process, or system. This digital representation allows for simulation, monitoring, and optimisation in a risk-free environment. Through sensors and data integration, the digital twin stays updated in real-time, enabling predictive maintenance, improved efficiency, and reduced downtime.",
    benefits: [
      {
        icon: serviceIcon1,
        title: 'Simulation and Testing',
        description:
          'Digital twins enable risk-free simulation and testing of various scenarios. This allows for the identification and resolution of potential issues before they arise in the physical world, saving time and resources.',
      },
      {
        icon: serviceIcon2,
        title: 'Monitoring and Prediction',
        description:
          "Real-time data integration with the digital twin provides continuous monitoring of the asset’s performance. This enables predictive maintenance, reducing downtime and improving overall efficiency.",
      },
      {
        icon: serviceIcon3,
        title: 'Optimisation',
        description:
          'By analysing data from the digital twin, processes can be optimised for maximum efficiency. This leads to cost savings and improved productivity.',
      },
      {
        icon: serviceIcon4,
        title: 'Decision Support',
        description:
          'The digital twin provides valuable insights that support informed decision-making. It helps organisations make better strategic choices regarding their assets and operations.',
      },
    ],
    worksSteps: [
      {
        step: 'STEP ONE',
        title: 'Requirements Collection and Analysis',
        subTitle:
          'Engagements focus on understanding client goals and the specifics of desired digital twins.',
        outputs: 'Comprehensive Brief, List of research topics.',
      },
      {
        step: 'STEP TWO',
        title: 'Technical Consultation and Planning',
        subTitle:
          "Technical strategies and tools are identified to support the development of digital twins.",
        outputs:
          'Revised Value Proposition, List of Main Assumptions to Prove, Initial User Stories.',
      },
      {
        step: 'STEP THREE',
        title: 'Data Collection and Organization',
        subTitle:
          'Assistance in collecting and organizing relevant data for creating effective digital twins.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP FOUR',
        title: 'Model Design and Development',
        subTitle:
          'Digital twin models are crafted to align with client-specific requirements and objectives.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP FIVE',
        title: 'Model Training and optimisation',
        subTitle:
          'Models undergo training and optimisation to ensure peak performance.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP SIX',
        title: 'Deployment and Integration',
        subTitle:
          'Digital twin models are integrated into client systems to enhance existing operations.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      }
    ],
  },
  {
    id: 5,
    icon: ziseLingxing,
    name: 'Internet of Things (IoT) Development',
    desc:
      'Delivering tailored IoT solutions to enhance operational efficiency and exceed expectations through innovative services.',
      subName:"What is Internet of Things (IoT) Development?",
      subDesc:"Internet of Things (IoT) Development refers to the advancement of technology that connects physical devices to the internet, enabling them to collect and exchange data. IoT development enhances device interoperability, improves efficiency, and facilitates remote monitoring and control, revolutionising various industries such as healthcare, transportation, and smart homes.",
    benefits: [
      {
        icon: serviceIcon1,
        title: 'Efficiency Improvement',
        description:
          'IoT allows devices to communicate and automate tasks, significantly improving operational efficiency. For example, smart building systems can optimise energy usage by adjusting heating, cooling, and lighting based on occupancy and environmental conditions.',
      },
      {
        icon: serviceIcon2,
        title: 'Remote Monitoring and Control',
        description:
          'With IoT, devices can be monitored and controlled remotely, providing convenience and timely interventions. This is especially beneficial in construction, where remote monitoring of machinery and site conditions can enhance safety and efficiency.',
      },
      {
        icon: serviceIcon3,
        title: 'Data Collection and Analytics',
        description:
          'IoT devices collect vast amounts of data, enabling detailed analytics. This data can inform decision-making, predict maintenance needs, and identify usage patterns in construction projects, leading to better resource allocation and project management.',
      },
      {
        icon: serviceIcon4,
        title: 'Safety and Security Enhancements',
        description:
          'IoT technology can enhance safety and security measures on construction sites. For instance, smart security systems can detect unauthorised access and alert authorities immediately, while monitoring equipment can identify potential hazards in real time.',
      },
    ],
    worksSteps: [
      {
        step: 'STEP ONE',
        title: 'Requirement Analysis and Planning',
        subTitle:
          "Discussions are initiated to understand clients' business needs and project objectives.",
        outputs: 'Comprehensive Brief, List of research topics.',
      },
      {
        step: 'STEP TWO',
        title: 'Technical Consultation and Solution Design',
        subTitle:
          'Tailored strategies and technical plans are developed to meet specific IoT needs.',
        outputs:
          'Revised Value Proposition, List of Main Assumptions to Prove, Initial User Stories.',
      },
      {
        step: 'STEP THREE',
        title: 'Hardware and Software Development',
        subTitle:
          'Both hardware and software components are developed, ensuring system compatibility and integration.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP FOUR',
        title: 'Device Connection and Data Acquisition',
        subTitle:
          'Connections between IoT devices and the internet are established, facilitating stable data transmission.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP FIVE',
        title: 'Data Analysis and Intelligent Applications',
        subTitle:
          'Data is leveraged to develop smart applications and decision-support systems.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope,Tech Spike, Business Model.',
      },
      {
        step: 'STEP SIX',
        title: 'Testing and optimisation',
        subTitle:
          'IoT systems undergo thorough testing and refinement to ensure optimal performance.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope,Tech Spike, Business Model.',
      },
      {
        step: 'STEP SEVEN',
        title: 'Deployment and Launch',
        subTitle:
          'IoT systems are deployed into production environments and integrated with client operations, with ongoing support provided.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope,Tech Spike, Business Model.',
      }
    ],
  },
  {
    id: 6,
    icon: blueLingxing,
    name: 'AIWare Development',
    desc:
      'Harness artificial intelligence to revolutionise your operations and discover new insights, with expertly tailored AI solutions enhancing your business’s strategic capabilities.',
      subName:"What is AIWare Development?",
      subDesc:"AIWare Development focuses on creating hardware and software solutions for automotive AI. It emphasises high performance, real-time AI inference for automated driving systems. With its aiWare NPU, it targets efficient and scalable AI processing, achieving up to 1000 TOPS and industry-leading efficiency for a range of neural network workloads.",
    benefits: [
      {
        icon: serviceIcon1,
        title: 'High Performance',
        description:
          'Cyberate Technologies offers exceptional performance, achieving up to 1000 TOPS (Tera Operations Per Second), ensuring fast and accurate AI inference for automated systems in real estate investment analysis.',
      },
      {
        icon: serviceIcon2,
        title: 'Real-time Inference',
        description:
          'With its capability to perform real-time AI inference, Cyberate Technologies is ideal for safety-critical applications in real estate investment, where split-second decisions can significantly impact investment outcomes.',
      },
      {
        icon: serviceIcon3,
        title: 'Scalability',
        description:
          'Cyberate Technologies solutions are designed for scalability, allowing them to adapt to the increasing demands of more complex neural networks and algorithms as real estate market analysis and investment strategies evolve.',
      },
      {
        icon: serviceIcon4,
        title: 'Industry-leading Efficiency',
        description:
          'Cyberate Technologies boasts industry-leading efficiency, optimising power consumption and performance for a range of neural network workloads. This is crucial for extending battery life and reducing energy costs in smart building management and real estate monitoring systems.',
      },
    ],
    worksSteps: [
      {
        step: 'STEP ONE',
        title: 'Requirement Collection and Analysis',
        subTitle:
          'Thorough discussions capture detailed project requirements and objectives.',
        outputs: 'Comprehensive Brief, List of research topics.',
      },
      {
        step: 'STEP TWO',
        title: 'Technical Consultation and Planning',
        subTitle:
          'Strategic plans and technical consultations are formulated based on client-specific needs.',
        outputs:
          'Revised Value Proposition, List of Main Assumptions to Prove, Initial User Stories.',
      },
      {
        step: 'STEP THREE',
        title: 'Data Preparation and Collection',
        subTitle:
          'Essential data sets are prepared and collected, ensuring data quality and completeness.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP FOUR',
        title: 'Model Design and Development',
        subTitle:
          'AI models are meticulously designed and developed to meet client specifications.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP FIVE',
        title: 'Model Training and optimisation',
        subTitle:
          'Continuous model training and optimisation enhance performance metrics.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope,Tech Spike, Business Model.',
      },
      {
        step: 'STEP SIX',
        title: 'Deployment and Integration',
        subTitle:
          'AI models are seamlessly integrated into client systems for enhanced operational efficiency.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope,Tech Spike, Business Model.',
      },
    ],
  },
  {
    id: 7,
    icon: greenLingxing,
    name: 'Generative AI',
    desc:
      'Generative AI is a type of artificial intelligence that uses machine learning, especially deep learning, to create new content like text, images, and audio based on patterns from historical data. It generates entirely new outputs, demonstrating creativity similar to humans.',
      subName:"What is Generative AI?",
      subDesc:"Generative AI is a type of artificial intelligence that uses machine learning models, especially deep learning techniques, to create new content such as text, images, audio, or video, based on patterns learned from historical data. It generates wholly new outputs rather than replicating existing data, showing creativity similar to humans. For example, Cyberate Technologies uses generative AI to design innovative construction blueprints and optimise building layouts.",
    benefits: [
      {
        icon: serviceIcon1,
        title: 'Creativity',
        description:
          'Generative AI excels at creating novel content, such as text, images, or music, that are not mere replicas of existing data. This creativity opens up new possibilities for artistic expression, advertising, and design in real estate marketing.',
      },
      {
        icon: serviceIcon2,
        title: 'Variety and Customisation',
        description:
          'It offers a wide range of customised outputs, tailored to individual preferences and needs. This personalisation enhances user experience and satisfaction, especially in areas like real estate marketing and investment presentations.',
      },
      {
        icon: serviceIcon3,
        title: 'Efficiency and Automation',
        description:
          'Generative AI automates content creation, significantly reducing manual effort and time. This efficiency boost is invaluable in industries like real estate, where rapid content generation for property listings and investment reports is crucial.',
      },
      {
        icon: serviceIcon4,
        title: 'Privacy and Data Security',
        description:
          'By generating synthetic data for machine learning training, Generative AI helps protect real user data, enhancing privacy and security. This is especially important in fields like real estate investment, where data confidentiality is paramount.',
      },
    ],
    worksSteps: [
      {
        step: 'STEP ONE',
        title: 'Initial Consultation and Needs Assessment',
        subTitle:
          "We hold an initial consultation with the client to understand their specific needs and objectives. This ensures all stakeholders are aligned before moving forward.",
        outputs: 'Comprehensive Brief, List of research topics.',
      },
      {
        step: 'STEP TWO',
        title: 'Proposal and Project Planning',
        subTitle:
          'We develop a detailed project proposal outlining the approach, timelines, and deliverables. The proposal is shared with the client for feedback and approval.',
        outputs:
          'Revised Value Proposition, List of Main Assumptions to Prove, Initial User Stories.',
      },
      {
        step: 'STEP THREE',
        title: 'Development and Regular Updates',
        subTitle:
          "During development, we maintain regular communication with the client to provide updates. Client feedback is encouraged at each stage to ensure the project meets their requirements.",
        outputs: 'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP FOUR',
        title: 'Testing and Quality Assurance',
        subTitle:
          'We conduct thorough testing and quality assurance to ensure the solution performs as expected. The client is involved in the testing phase to validate results and provide final feedback.',
        outputs: 'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP FIVE',
        title: 'Deployment and Post-Deployment Support',
        subTitle:
          'Once satisfied with the solution, we proceed with deployment and provide comprehensive training. Ongoing support and maintenance are offered to address any post-deployment issues.',
        outputs: 'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
    ],
  },
  {
    id: 8,
    icon: zfx,
    name: 'Software Product Engineering',
    desc:
      'Software Product Engineering focuses on the systematic development, maintenance, and enhancement of software products. It emphasises creating scalable, robust, and user-friendly software through rigorous engineering practices and continuous innovation.',
      subName:"What is Software Product Engineering?",
      subDesc:"Software Product Engineering focuses on the systematic development, maintenance, and enhancement of software products. Cyberate Technologies designs, implements, tests, and delivers high-quality software that meets user needs and business goals. This field emphasises creating scalable, robust, and user-friendly products through rigorous engineering practices and continuous innovation. ",
    benefits: [
      {
        icon: serviceIcon1,
        title: 'Structured Development Process',
        description:
          'Software Product Engineering follows a structured and systematic approach to software development. At Cyberate Technologies, we ensure that all stages of the development cycle, from requirements gathering to deployment, are carefully planned and executed, minimising risks and maximising efficiency.',
      },
      {
        icon: serviceIcon2,
        title: 'Quality Assurance',
        description:
          'The discipline emphasises quality control measures, including rigorous testing and code reviews, to ensure the delivered product is of high quality and meets the expected standards. This reduces the chances of post-deployment issues and enhances customer satisfaction. For instance, our real estate investment analysis tools undergo stringent testing to guarantee reliable performance.',
      },
      {
        icon: serviceIcon3,
        title: 'Flexibility and Scalability',
        description:
          'Software Product Engineering takes into account future growth and scalability needs. Cyberate Technologies designs products to adapt to changing requirements and handle increasing workloads, making them suitable for long-term use and evolution. This is crucial in real estate investment, where market dynamics can shift rapidly.',
      },
      {
        icon: serviceIcon4,
        title: 'Customer Focus',
        description:
          "The customer is at the centre of the Software Product Engineering process. By involving customers throughout the development cycle and gathering continuous feedback, the engineering team at Cyberate Technologies ensures that the final product aligns closely with the customer’s needs and expectations, particularly in creating tailored solutions for real estate investment analysis.",
      },
    ],
    worksSteps: [
      {
        step: 'STEP ONE',
        title: 'Initial Consultation and Requirements Gathering',
        subTitle:
          'We start with an initial consultation to understand the client’s specific needs and project goals. This ensures we gather all necessary requirements before moving forward with development.',
        outputs: 'Comprehensive Brief, List of research topics.',
      },
      {
        step: 'STEP TWO',
        title: 'Proposal and Project Planning',
        subTitle:
          'After the consultation, we prepare a detailed project proposal outlining our approach, timelines, and deliverables. The proposal is then shared with the client for feedback and approval.',
        outputs:
          'Revised Value Proposition, List of Main Assumptions to Prove, Initial User Stories.',
      },
      {
        step: 'STEP THREE',
        title: 'Development and Regular Updates',
        subTitle:
          'During the development phase, we maintain regular communication with the client to provide progress updates. This helps ensure the project stays on track and aligns with the client’s expectations.',
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP FOUR',
        title: 'Testing and Quality Assurance',
        subTitle:
          "We involve the client in the testing phase to validate the software's functionality and quality. This step includes rigorous testing and quality assurance processes to meet the highest standards.",
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
      {
        step: 'STEP FIVE',
        title: 'Deployment and Post-Deployment Support',
        subTitle:
          "Once the software is ready, we proceed with deployment and provide training to the client’s team. We also offer ongoing support and maintenance to address any issues that may arise post-deployment.",
        outputs:
          'Complete User Stories, Key BPMN (Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.',
      },
    ],
  },
]
