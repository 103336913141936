export const user_config = {
  Auth: {
    userPoolId: process.env.REACT_APP_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOLWEBCLIENTID,
    mandatorySignIn: process.env.REACT_APP_MANDATORYSIGNIN,
  },
};

export const admin_config = {
  Auth: {
    userPoolId: process.env.REACT_APP_ADMIN_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_ADMIN_WEBCLIENTID,
    mandatorySignIn: process.env.REACT_APP_MANDATORYSIGNIN,
  },
};
