export const arTrans = {
  dashboard: "لوحة القيادة",
  applications: "التطبيقات",
  documents: "وثائق",
  services: "خدمات",
  "your-company": "شركتك",
  "need-help?": "تحتاج مساعدة؟",
  logout: "تسجيل خروج",
  "formation-info": "معلومات التكوين",
  "setup-type": "نوع الإعداد",
  "purpose&activities": "الغرض والأنشطة",
  "visa-allocation": "تخصيص التأشيرات",
  amenities: "وسائل الراحة",
  "let’s-start-with-the-basics": "هيا لنبدأ مع الأساسيات",
  "save-&-continue-later": "احفظ واستمر لاحقًا",
  back: "الى الخلف",
  next: "التالي",
  "a-bit-more-about-your-needs": "أكثر قليلاً عن احتياجاتك",
  "about-your-new-venture": "عن مشروعك الجديد",
  "something-major-is-forming-up": "شيء كبير يتشكل",
  "final-step-we-are-almost-done": "الخطوة الأخيرة ، نحن على وشك الانتهاء",
  "almost-there!": "100٪ - أوشكت على الانتهاء!",
  "pick-up-where-you-left-off": "التقط من حيث توقفت",
  "no-account-text": "ليس لديك حساب؟",
  login: "تسجيل الدخول",
  "sign-up": "اشتراك",
  "forgot-password": "هل نسيت كلمة السر",
  "welcome-back!": "مرحبًا بعودتك!",
  "with-sallis-handle-everything-online":
    "مع sallis ، يمكنك التعامل مع كل شيء عبر الإنترنت",
  "issue-visas": "إصدار التأشيرات",
  "trading-license": "الحصول على رخصة تجارية",
  "company-in-dubai": "تأسيس شركة في دبي",
  "track-your-applications-online": "تتبع طلباتك عبر الإنترنت",
  "assign-power-of-attorney": "تعيين التوكيل",
  "handle-everything-online": "التعامل مع كل شيء عبر الإنترنت",
  "total-number-of-shareholders": "ما هو العدد الإجمالي للمساهمين؟",
  "partner-information": "معلومات الشريك",
  "get-started-the": "ابدأ",
  sallis: "ساليس",
  way: "طريق",
  "with-sallis-start-and-manage-company-online":
    "مع sallis ، يمكنك بدء شركتك وإدارتها عبر الإنترنت",
  "create-your-account": "أنشئ حسابك",
  "provide-company-details": "تقديم بيانات الشركة",
  "submit-required-documents": "إرسال المستندات المطلوبة",
  "pay-online-in-the-portal": "ادفع عبر الإنترنت في البوابة",
  "reset-password": "إعادة تعيين كلمة المرور",
  "save-new-password": "احفظ كلمة المرور الجديدة",
  "confirm-password": "تأكيد كلمة المرور",
  "new-password": "كلمة مرور جديدة",
  "get-you-in-first": "دعنا نأخذك في البداية",
  "first-name": "الاسم الأول",
  "last-name": "اسم العائلة",
  email: "بريد إلكتروني",
  "mobile-number": "رقم الهاتف المحمول",
  password: "كلمة المرور",
  "create-account": "إنشاء حساب",
  "already-have-account": "هل لديك حساب ؟",
  "confirm-sign-up": "قم بتأكيد التسجيل",
};

export default arTrans;
