/* eslint-disable simple-import-sort/imports */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Col, Row } from 'antd'
import { useNavigate } from 'react-router-dom'
import fullLogo from "assets/images/logo.png";
import css from './Footer.module.scss'




const HomePageFooter = (props: any) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/'); // Navigate to the homepage
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <footer className={css.footer}>
      <div className={css.container}>
        <div className={css.containerMain}>
          <Row gutter={16}>
            <Col lg={6} sm={24} xs={24}>
              <img src={fullLogo} className={css.fullLogo} onClick={handleClick} alt="fullLogo" width={128} height={78} />

              <div className={css.containerBox}>
                <div className={css.containerBoxCol}>
                  <div className={css.imgCenter}>
                    <a href="/">
                      {/* <img
                        width="108"
                        height="68"
                        src="logo.png"
                     
                      /> */}
                      {/* <noscript>
                        <img
                          width="48"
                          height="48"
                          src="https://altar.io/wp-content/uploads/2023/10/altar-logo__small__transparent-color.svg"
                          alt=""
                        />
                      </noscript>{' '} */}
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={18} sm={24} xs={24}>
              <Row gutter={16}>
                <Col lg={12} sm={12} xs={12}>
                  <div className={css.containerBox}>
                    <div className={css.containerBoxCol}>
                      {/* <div className={css.title}>
                        <h5>Global</h5>{' '}
                      </div> */}
                      <div className={css.list}>
                        <ul>
                          <li>
                            <a href="/servicesMain">
                              <span>Service</span>
                            </a>
                          </li>
                          <li>
                            <a href="/aboutUs">
                              <span>About Us</span>
                            </a>
                          </li>
                          {/* <li>
                            <a href="/blog">
                              <span>Blog</span>
                            </a>
                          </li> */}
                          <li>
                            <a href="/caseStudy">
                              <span>Case Study</span>
                            </a>
                          </li>
                          <li>
                            <a href="/caseStudy">
                              <span>Contact us</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12} sm={12} xs={12}>
                  <div className={css.containerBox}>
                    <div className={css.containerBoxCol}>
                      <div className={css.title}>
                        <h5>Contact</h5>
                      </div>

                      <div className={css.title}>
                        <h6 style={{ color: "white", fontSize: 20 }}>Adelaide</h6>
                      </div>
                      <div className={css.list}>
                        <ul>
                          <li>
                            <a href="tel:+61882579123">
                              <span>Phone : +61 (08) 82579123</span>
                            </a>
                          </li>
                          <li>
                            <a href="mailto:info@cyberate.com.au">
                              <span>Email : info@cyberate.com.au</span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span>
                                Address : 3/22-28 Melbourne Street, North
                                Adelaide, SA 5006, Australia
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className={css.title}>
                        <h6 style={{ color: "white", fontSize: 20, paddingTop: 30 }}>Auckland</h6>
                      </div>
                      <div className={css.list}>
                        <ul>
                          <li>
                            <a href="mailto:info@cyberate.tech">
                              <span>Email : info@cyberate.tech</span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span>
                                Address : 17B Farnham Street, Parnell, Auckland, 1052, New Zealand
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className={css.containerHr}>
            <div className={css.containerHrMain}>
              <div className={css.containerHrMain}>
                <span></span>
              </div>
            </div>
          </div>

          <div className={css.containerReserved}>
            <div className={css.reservedLeft}>
              © Copyright 2024 Cyberate Technologies Pty Ltd, All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default HomePageFooter
