export const HomePageTransAR = {
  home: "الصفحة الرئيسية",
  resources: "مصادر",
  contact: "اتصل",
  "cost-calculator": "حاسبة التكلفة",
  "customer-portal": "بوابة العملاء",
  "launch-firm-online": "",
  "try-now": "جرب الآن",
  "start-business": "بدء الأعمال بدون مشاكل.",
  "company-formation-procedure":
    "يجعل إجراءات تأسيس الشركة في الإمارات العربية المتحدة سهلة - هذا هو الشيء الذي نقوم به.",
  "you-can-form": "يمكنك تشكيل ملف",
  "uae-company": "شركة إماراتية",
  "visa-bank-account-online": "",
  "web-portal-helps": "",
  "setup-your-business": "",
  "fulfill-needs": "",
  with: "",
  "it-is-a": "، إنها",
  "painless-fast": "غير مؤلم وسريع",
  and: "و",
  efficient: "فعالة",
  process: "معالجة",
  "how-it-works": "كيف تعمل",
  faqs: "FAQs",
  "how-it-works-desc": "",
  "legal-entity-form": "",
  "legal-entity-form-desc1": "",
  "legal-entity-form-desc2": "",
  "share-founder-stock": "",
  "share-founder-stock-desc1": "",
  "share-founder-stock-desc2": "",
  "account-with-stripe": "",
  "account-with-stripe-desc": "",
  "helpful-community": "",
  "helpful-community-desc": "",
  "services-we-provide": "",
  "company-setup": "",
  "mainland-business-licenses": "",
  memorandum: "",
  "activity-desc-certificate": "",
  checkout: "",
  "visa-permit": "",
  "investor-visa": "",
  "residence-visa": "",
  "golden-visa": "",
  "visa-status-change": "",
  "emirated-id-services": "",
  "financial-solution": "",
  "online-application-convenience": "",
  use: "",
  "coop-bank": "",
  "medical-insurance": "",
  faq: "",
  "faq-one": "",
  "faq-one-desc-para1": "",
  "faq-one-desc-para2": "",
  "faq-one-desc-para3": "",
  "faq-two": "",
  "faq-two-desc": "",
  "faq-three": "",
  "faq-three-desc-para1": "",
  "faq-three-desc-para2": "",
  "faq-three-desc-para3": "",
  "faq-three-desc-para4": "",
  "faq-four": "",
  "faq-four-desc-para1": "",
  "faq-four-desc-para2": "",
  does: "",
  "faq-five": "",
  "faq-five-desc": "",
  company: "",
  "visa-ssuance": "",
  "residence-permit": "",
  "financial-services": "",
  "financial-solutions": "",
  "privacy-policy": "",
  "terms-and-conditions": "",
  "contact-info": "",
  "sallis-address": "",
  "sallis-email": "",
  "sallis-phone": "",
  "copyright-2023 ": "",
  sallis: "",
  "all-rights-reserved": "",
};

export default HomePageTransAR;
